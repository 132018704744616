export const environment = {
  production: true,
  APP_Ocp_Apim_Subscription_Key: 'f7a1caf6eeb34c418512ab4d46d96862',
  LUMINA_APP_BASE_URL: "https://lumina.dnv.com",
  LUMINA_APP_CLIENT_ID: "d1ed86f4-22d1-4de1-aaf0-792d3f943c76",
  LUMINA_APP_LOGOUT_URL: "https://developer.veracity.com/auth/logout",
  APP_BASE_URL: 'https://api.dnv.com/BALUMINA-API-PROD/v0/',
  ENV: 'PROD',
  SERVICE_ID:'32f3ab2d-a2a3-45c3-bf26-dc7869887cc5',
  appInsights: {
    instrumentationKey: 'bf2ec8e3-4c06-4ea8-aef2-620b092bce33',
  },
  
};
